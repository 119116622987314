import { Tab as Tab } from 'bootstrap';
import Swiper, { EffectFade, Navigation } from "swiper";
import 'swiper/swiper.scss'
import '/node_modules/swiper/modules/effect-fade/effect-fade.scss'
import '/node_modules/swiper/modules/navigation/navigation.scss'

import Parallax from 'parallax-js'
// import anime from 'animejs/lib/anime.es.js';

import AOS from 'aos';
import 'aos/dist/aos.css';

import {initForms} from './forms.js'

Swiper.use([EffectFade, Navigation])
document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.main-slider')) {
        initMainSlider()
    }
    AOS.init()
    initForms()
})

function initMainSlider () {
    let scene
    const swiper = new Swiper ('.main-slider', {
        loop: true,
        effect: 'fade',
        speed: 0,
        allowTouchMove: false,
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: function () {
                scene = createScene()
                starAnimation(0, this, true)
            }
        }
    })
    let observer = shadowObserver ()
    swiper.on('transitionEnd', function () {
        const index = swiper.realIndex
        observer.disconnect()
        observer = shadowObserver()
        scene.destroy()
        scene = createScene()
        starAnimation(index, swiper, false)
    })
    swiper.on('transitionStart', function () {
        // document.querySelector('.swiper-slide.come-in').classList.add('come-out')
    })
   
}

function shadowObserver () {

    const target = document.querySelector('.swiper-slide-active .layer.pack');
    const shadow = document.querySelector('.swiper-slide-active .layer .shadow')

    const config = {
        attributes: true
    }
    const callback = function (mutationsList, observer) {
        let translateY = target.style.transform.match(/-?\d+\.?\d?px\b/g)[1].replace('px', '')
        shadow.style.transform = `scale(${1 - translateY/500})`
    }

    const observer = new MutationObserver(callback)
    observer.observe(target, config)
    return observer
}


function createScene() {
    const sceneElement = document.querySelector('.swiper-slide-active .scene')
    const scene = new Parallax (sceneElement, {
        // scalarX: 100,
        // scalarY: 100,
        selector: '.swiper-slide-active .layer',
        relativeInput: true,
        hoverOnly: true,
        inputElement: document.querySelector('.swiper-slide-active')
    })
    return scene
}

function starAnimation(index = 0, swiper, start) {

    if (document.querySelectorAll('.bgs-container>div.animate')) {
        Array.from(document.querySelectorAll('.bgs-container>div.animate')).forEach(el => {
            el.classList.remove('animate')
        })
    }
    document.querySelector(`.bgs-container>div:nth-child(${index + 1})`).classList.add('animate')
    // console.log(document.querySelector(`.bgs-container>div:nth-child(0)`))
    if (!start) {
        try {
            const prevSlide = document.querySelector('.swiper-slide.come-in');
            prevSlide.classList.remove('come-in')
            // console.log(swiper.activeIndex, swiper.previousIndex)

            let direction;
            if (swiper.activeIndex - swiper.previousIndex === 1|| -1 > swiper.activeIndex - swiper.previousIndex  ) {
                direction = 'right'
            }else {
                direction = 'left'
            }

            const className = direction == 'right' ? ['come-out', 'right'] : ['come-out', 'left']
            prevSlide.classList.add(...className)
            
            const comeOutTargets = document.querySelectorAll('.swiper-slide.come-out .layer')
            const lastAnimated = comeOutTargets[comeOutTargets.length - 2]
            lastAnimated.onanimationend = () => {
                const target = document.querySelector('.swiper-slide-active')
                prevSlide.classList.remove('come-out', 'right', 'left')
                target.classList.add('come-in')
            }
        } catch (error) {
            // console.log(error)
        }
    }else {
        const target = document.querySelector('.swiper-slide-active')
            // target.classList.remove('come-out')
            target.classList.add('come-in')
    }
    
   
}

