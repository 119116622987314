export function initForms(){
    // document.querySelector('input[name=policy]').addEventListener('invalid', function(e){
    //     e.target.nextElementSibling.classList.add('error')
    // })
    // document.querySelector('input[name=policy]').addEventListener('change', function(e){
    //     if (e.target.checked){
    //         e.target.nextElementSibling.classList.remove('error')
    //     }
    // })

    let forms = document.querySelectorAll('form')
    Array.from(forms).forEach(form => {
        form.addEventListener('submit', sendRequest)    
    });

    async function sendRequest (e) {
        e.preventDefault()
        let url = e.target.dataset.action
        let body = new FormData(e.target)
        let response = await fetch(url, { method: 'POST', body: body })
        let data = await response.text()
        let el = e.target.querySelector('.response');
        el.textContent = data
        el.classList.toggle('show')
        setTimeout(function(){
            Array.from(e.target.querySelectorAll('input, textarea')).forEach(el => {
                el.value = '';
            })
            el.classList.toggle('show')
        }, 3000)
    }

}